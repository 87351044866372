import LoginPage from "../pages/LoginPage";
import RegisterPage from "../pages/RegisterPage";
// import Home from "../pages/Home";
// import StartPage from '../pages/StartPage'
// import AboutPage from "../pages/AboutPage";
// import MyProjPage from "../pages/MyProjPage";
// import NetWorkPage from "../pages/NetWorkPage";
// import DeepNetPage from "../pages/DeepNetPage";
import LandingPage from "../pages/LandingPage";

import Settings from "../pages/Settings"
import TestPage from "../pages/TestPage"
import VacancyListPage from "../pages/VacancyListPage"
import CourseList from "../pages/CourseList"
import CourseDetail from "../pages/CourseDetail"
import CreateCoursePage from "../pages/CreateCoursePage"


import HomePage from "../pages/LandingSet/HomePage"
import CourseGridPage from "../pages/LandingSet/CourseGridPage"
import CourseDetailsPage from "../pages/LandingSet/CourseDetailsPage"
import EventDetailsPage from "../pages/LandingSet/EventDetailsPage"
import LoginNewPage from "../pages/LandingSet/LoginNewPage"
import RegistrationPage from "../pages/LandingSet/RegistrationPage"

import CourseGrafPage from "../pages/LandingSet/courses/CourseGrafPage"
import CourseBugalterPage from "../pages/LandingSet/courses/CourseBugalterPage"

import CourseCouchPage from "../pages/LandingSet/courses/CourseCouchPage"
import CourseCallPage from "../pages/LandingSet/courses/CourseCallPage"
import CourseSellPage from "../pages/LandingSet/courses/CourseSellPage"
import CourseTargetPage from "../pages/LandingSet/courses/CourseTargetPage"
import MyCourseGridPage from "../pages/LandingSet/MyCourseGridPage";
import MyCourseDetailsPage from "../pages/LandingSet/MyCourseDetailsPage";
import CreateMyCoursePage from "../pages/LandingSet/CreateMyCoursePage";
import MyTeacherCourseGridPage from "../pages/LandingSet/MyTeacherCourseGridPage";
import MyTeacherCourseDetailsPage from "../pages/LandingSet/MyTeacherCourseDetailsPage";

import PublicCourseGrafPage from "../pages/LandingSet/courses/publicCourses/PublicCourseGrafPage"
import PublicCourseBugalterPage from "../pages/LandingSet/courses/publicCourses/PublicCourseBugalterPage"
import PublicCourseCallPage from "../pages/LandingSet/courses/publicCourses/PublicCourseCallPage";
import PublicCourseSellPage from "../pages/LandingSet/courses/publicCourses/PublicCourseSellPage";
import PublicCourseTargetPage from "../pages/LandingSet/courses/publicCourses/PublicCourseTargetPage";
import ContactPage from "../pages/LandingSet/ContactPage";




export const publicRoutes = [
    {path: '/login', element: <LoginPage/>},
    {path: '/loginn', element: <LoginNewPage/>},
    {path: '/register', element: <RegisterPage/>},
    {path: '/registerr', element: <RegistrationPage/>},
    {path: '/start', element: <HomePage/>},
    { path: '/course-details:1', element: <PublicCourseGrafPage/> },
    { path: '/course-details:2', element: <PublicCourseBugalterPage/> },
    { path: '/course-details:4', element: <PublicCourseCallPage/> },
    { path: '/course-details:5', element: <PublicCourseSellPage/> },
    { path: '/course-details:6', element: <PublicCourseTargetPage/> },


    { path: '/future-teacher', element: <ContactPage/> },

    // { path: '/event-details', element: <EventDetailsPage/> },
]

export const privateRoutes = [
    {path: '/home', element: <Settings/>},
    {path: '/settings', element: <Settings/>},
    {path: '/vacancylist', element: <VacancyListPage/>},
    {path: '/my-courselist', element: <MyCourseGridPage/>},
    { path: '/course/:id', element: <CourseDetail /> },

    { path: '/mycourse-details/:id', element: <MyCourseDetailsPage/> },

    {path: '/test', element: <TestPage/>},
    { path: '/course-grid-page', element: <CourseGridPage/> },
    { path: '/course-details:1', element: <CourseGrafPage/> },
    { path: '/course-details:2', element: <CourseBugalterPage/> },
    { path: '/course-details:4', element: <CourseCallPage/> },
    { path: '/course-details:5', element: <CourseSellPage/> },
    { path: '/course-details:6', element: <CourseTargetPage/> },
    { path: '/course-details', element: <CourseDetailsPage/> },

    
]


export const studentRoutes = [
    {path: '/home', element: <Settings/>},
    {path: '/settings', element: <Settings/>},
    {path: '/vacancylist', element: <VacancyListPage/>},
    {path: '/my-courselist', element: <MyCourseGridPage/>},
    { path: '/course/:id', element: <CourseDetail /> },

    { path: '/mycourse-details/:id', element: <MyCourseDetailsPage/> },

    {path: '/test', element: <TestPage/>},
    { path: '/course-grid-page', element: <CourseGridPage/> },
    { path: '/course-details:1', element: <CourseGrafPage/> },
    { path: '/course-details:2', element: <CourseBugalterPage/> },
    { path: '/course-details:4', element: <CourseCallPage/> },
    { path: '/course-details:5', element: <CourseSellPage/> },
    { path: '/course-details:6', element: <CourseTargetPage/> },
    { path: '/course-details', element: <CourseDetailsPage/> },
];


// Роутинг для викладачів
export const teacherRoutes = [
    {path: '/home', element: <Settings/>},
    {path: '/settings', element: <Settings/>},
    {path: '/vacancylist', element: <VacancyListPage/>},
    {path: '/my-courselist', element: <MyTeacherCourseGridPage/>},
    { path: '/course/:id', element: <CourseDetail /> },
    { path: '/courseCreate', element: <CreateMyCoursePage /> },
    

    { path: '/mycourse-details/:id', element: <MyTeacherCourseDetailsPage/> },

    {path: '/test', element: <TestPage/>},
    { path: '/course-grid-page', element: <CourseGridPage/> },
    { path: '/course-details:1', element: <CourseGrafPage/> },
    { path: '/course-details:2', element: <CourseBugalterPage/> },
    { path: '/course-details:4', element: <CourseCallPage/> },
    { path: '/course-details:5', element: <CourseSellPage/> },
    { path: '/course-details:6', element: <CourseTargetPage/> },
    { path: '/course-details', element: <CourseDetailsPage/> },
];
